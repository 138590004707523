import { GridRowsProp, GridValidRowModel } from '@mui/x-data-grid';
import React, { useState, useEffect } from 'react'
import PbGrid from '../../components/grid/grid.component';
import { PbColumns } from '../../components/grid/grid-constants';
import Header from '../../components/header/header.component'
import { FileResponse, FileSource } from '../../components/interfaces/FileResponse';
import Loader from '../../components/loader/loader.component';
import PdbDropbox from '../../components/pbDropbox/dropbox.component';
import styles from '../../index.module.css'
import { CollectionService } from '../../services/collection.service';
import SheetItem, { AddButtonPosition } from '../../components/search/sheet-item.component';
import FileHelper from '../../helpers/filehelper.helper';
import { toast } from 'react-toastify';
import RepertoireService from '../../services/repertoire.service';

export default function CollectionList() {
    const [list, setList] = useState<GridRowsProp>()
    const [searching, setSearching] = useState(false);
    const [current, setCurrent] = useState<FileResponse>();

    const drop = new PdbDropbox();
    const collectionService = new CollectionService();
    const repertoireService = new RepertoireService();

    useEffect(() => {
        loadList();
    }, [list])

    const loadList = async () => {
        if (list) return;
        await setSearching(true);

        const { data } = await collectionService.getAll();

        if (data) {

            var rows: GridValidRowModel[] = [];

            (data as FileResponse[]).map(async (value, index) => {
                await rows.push({
                    id: index,
                    title: value.address,
                    address: value.address,
                    extension: value.extension,
                    source: FileSource.Dropbox,
                    view: value.serverPath.endsWith('pdf') && !navigator.pdfViewerEnabled ? '🔽' : '👀',
                    repertoire: '➕',
                    serverPath: value.serverPath,
                })
            })

            await setList(rows)
        }

        await setSearching(false);
    }

    const onLineClick = async (e: any) => {
        switch (e.field) {
            case 'view':
                return await onDownloadClick(e);
            case 'repertoire':
                return await onAddRepertoireClick(e);

            default:
                break;
        }
    }

    const onAddRepertoireClick = async (e: any) => {
        await onAddRepertoireImageClick({
            address: e.row.address,
            extension: e.row.extension,
            serverPath: e.row.serverPath,
            source: e.row.source
        })
    }

    const onAddRepertoireImageClick = async (file: FileResponse) => {
        const localFile = current ? current : file;

        try {
            await repertoireService.add(localFile);

            toast.success(`Partitura '${localFile.address}' adicionada ao seu repertório!`);

        } catch {
            toast.error(`Não foi possivel adicionar a partitura '${localFile.address}' ao repertório.`)
        }
    }

    const onDownloadClick = async (e: any) => {
        await setCurrent(undefined);

        if (e.row.extension === 'pdf' && !navigator.pdfViewerEnabled) {
            const file = await drop.GetFromServer(e.row.serverPath);
            const titleWithExtension = e.row.serverPath.split("/").slice(-1).pop();
            FileHelper.TryDownload(titleWithExtension, file);
        }
        else 
            await setCurrent(e.row);        
    }

    return <div className={styles.main}>
        <Header />
        <h2>Acervo</h2>
        {searching && <Loader />}
        {list && <PbGrid columns={PbColumns} rows={list} onLineClick={onLineClick} />}
        {current && <SheetItem item={current!} addButton={{ position: AddButtonPosition.High, onClick: onAddRepertoireImageClick }} />}
    </div>
}