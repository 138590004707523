import React from 'react'
import { Title } from "../header/header.component";
import style from './member-header.module.css'

export default function MemberHeader() {
    const current = window.location.pathname;
    const links = [
        {
            href: '/login',
            text: 'Login'
        },
        {
            href: '/contribute',
            text: 'Contribuir'
        }
    ]

    return <>
        {<div className={style.links}>
            {links.filter(l => l.href !== current).map((l, index) => <a key={index} href={l.href} className={style.item}>{l.text}</a>)}
        </div>}
        <Title />
    </>
}