import { AxiosResponse } from 'axios';
import { HttpClient } from '../../components/infra/httpClient.component';

export interface ContributeRequest {
    items: Array<ContributeItemRequest>,
    user: string | null | undefined
}

export interface ContributeItemRequest {
    content: string,
    name: string
}

export class ContributeService {
    private request: HttpClient;

    constructor() {
        this.request = new HttpClient(true);
    }

    public addImages(request: ContributeRequest): Promise<AxiosResponse<any, any>> {
        var formData = new FormData();

        request.items.forEach((item, index) => formData.append(`contribution_${index}`, JSON.stringify(item)));

        formData.append('user', request.user!);

        return this.request.post(`contribute`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    }
}

export default ContributeService;