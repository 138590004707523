import React from 'react';
import loading from "../../assets/img/tenor.gif";
import './index.css';

interface ProcessingProps {
    isLoading: boolean
}

export function Processing({ isLoading } : ProcessingProps) {
    return <>
    {isLoading && <div className="processing-container">
        <div className="processing">            
            <img src={loading} alt='Imagem carregando as informações' width={100} height={100} />
        </div>
    </div>}

    </>
}