import React, { useContext, useState } from 'react'
import style from './contribute.module.css'
import mainstyle from '../../index.module.css'
import MemberHeader from '../../components/member-header/member-header.component'
import ImageUploader, { ImageUploaderProps } from '../../components/image-uploader/image-uploader.component'
import { Button, TextField } from '@mui/material'
import ConfirmationDialog from '../../components/dialogs/confirmation.dialog'
import { toast } from 'react-toastify'
import { LoadingContext } from '../../providers/lolading.provider'
import ContributeService, { ContributeItemRequest } from './contribute.service'
import { useSearchParams } from 'react-router-dom'

interface Contribution {
    items: Array<ContributionItem>,
}

interface ContributionItem {
    id: string,
    content: string | undefined,
    text: string,
    size?: number
}

const imagePrefix = `partitura_img_`;
const maxSize = 5;

export default function Contribute() {
    const [current, setCurrent] = useState<Contribution>({ items: [{ id: `${imagePrefix}0`, content: undefined, text: '' }] });
    const [dialogConfirmVisible, setDialogConfirmVisible] = useState(false);
    const [sendingInfo, setSendingInfo] = useState(false);
    const [infoSent, setInfoSent] = useState(false);

    const [searchParams, _] = useSearchParams();
    const userName = searchParams.get("user");

    const service = new ContributeService();

    const { setIsLoading } = useContext(LoadingContext);

    const addImage = async (id: string, data: any, text: string, size: number): Promise<boolean> => {
        if (current.items.findIndex(f => f.content === data) !== -1) {
            toast.warn('Partitura já adicionada na lista atual');
            return false;
        }

        const totalSize = current.items.map(i => i.size ? i.size : 0).reduce((prev, curr) => prev += curr, 0) + size;

        if (totalSize > maxSize) {
            toast.warn(`O total de arquivos ultrapassou o limite de ${maxSize} Mb. Envie menos arquivos.`);
            return false;
        }

        const toModifyIndex = current.items.findIndex(f => f.id == id);

        current.items[toModifyIndex].content = data;
        current.items[toModifyIndex].text = text.split('.')[0];

        const newImages = current.items[current.items.length - 1].content !== undefined
            ? current.items.concat({ id: `${imagePrefix}${current.items.length}`, content: undefined, size, text: '' })
            : [...current.items];

        await setCurrent({ items: newImages });

        return true;
    }

    const cleanImage = async (id: string) => {
        const toRemoveIndex = current.items.findIndex(f => f.id == id);

        const newImages = [...current.items];
        newImages.splice(toRemoveIndex, 1);
        newImages.forEach((item, index) => item.id = `${imagePrefix}${index}`)

        await setCurrent({ ...current, items: newImages });
    }

    function handleDrag(props: ImageUploaderProps): void {
        console.log(props)
    }

    function handleDrop(props: ImageUploaderProps): void {
        console.log(props)
    }

    const onConfirm = async () => {
        const localCurrent = { ...current };

        await setSendingInfo(true);
        await setIsLoading(true);

        try {
            let items: ContributeItemRequest[] = [];

            validItems(localCurrent.items)
                .forEach(image => items = items.concat({
                    content: image.content!,
                    name: image.text!
                }));

            await service.addImages({
                items,
                user: userName
            });

            await setInfoSent(true);
        } catch {
            toast.error('Não foi possivel concluir sua contribuição.');
        }
        finally {
            await setIsLoading(false);
        }
    }

    const validItems = (items?: ContributionItem[]) => (items ? items : current.items).filter(i => i.content !== undefined);

    const validate = async () => {
        if (sendingInfo) return;

        if (!current.items || 
            validItems().length <= 0 ||
            validItems().some(c => !c.text)) {
            toast.error('Há campos obrigatórios que precisam ser preenchidos!');
            return;
        }

        await setDialogConfirmVisible(true)
    }

    return <>
        <div className={mainstyle.main}>
            <MemberHeader />
            <h2 className={style.title}>{!infoSent ? 'Envie suas partituras' : 'Obrigado por sua contribuição!'}</h2>
            {!infoSent && <>
                {current.items.map((item, index) => <div style={{
                    borderBottom: '1px solid #eaeaea'
                }}>
                    <div className={mainstyle.lines}>
                        <ImageUploader
                            id={`partitura_img_${index}`}
                            key={index}
                            onChange={addImage}
                            onClickImage={cleanImage}
                            photo={item.content}
                            handleDrag={handleDrag}
                            handleDrop={handleDrop}
                            clearInternalWhenClick={false}
                        />
                    </div>
                    <div className={mainstyle.lines}>
                        <TextField
                            key={index + 1000}
                            InputLabelProps={{ shrink: true }}
                            className={mainstyle.txtboxfull}
                            id={`titulo-partitura-${index}`}
                            label="Titulo"
                            variant="outlined"
                            placeholder='Titulo da partitura (adicione nome do artista, album e ano se quiser).'
                            value={item.text}
                            onChange={(e) => {
                                const currItem = current.items[index];
                                currItem.text = e.target.value;

                                const newItems = [...current.items];

                                newItems.splice(index, 1, currItem);

                                setCurrent({ items: newItems });
                            }}
                            error={!item.text}
                            helperText={!item.text ? 'Campo obrigatório' : ''}
                        />
                    </div>
                </div>)}

                <div className={mainstyle.lines}>
                    <Button onClick={validate} color="secondary">
                        Enviar
                    </Button>
                </div>
            </>}
        </div>

        {dialogConfirmVisible && <ConfirmationDialog
            title={`Confirmar o envio das '${validItems().length}' imagem(ns) ?`}
            onConfirm={onConfirm}
            text={'Clique em confirmar para iniciar o envio'}
            onClose={async () => await setDialogConfirmVisible(false)}
        />}
    </>
}