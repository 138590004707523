import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.module.css'
import reportWebVitals from './reportWebVitals';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import ErrorPage from './routes/error-page';
import LastSearches from './pages/last-searches/last-searches.pages';
import Footer from './components/footer/footer.component';
import MyRepertoire from './pages/my-repertoire/my-repertoire.page';
import CollectionList from './pages/collection-list/collection-list.page';
import { AuthProvider } from './providers/auth.provider';
import Login from './pages/login/login.page';
import { ProtectedRoute } from './routes/protected-route';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BeMember from './pages/be-member/member.page';
import MainSearch from './pages/main-search/main-search.page';
import { store } from './redux-ts';
import { Provider } from 'react-redux';
import Contribute from './pages/contribute/contribute.page';
import { LoadingProvider } from './providers/lolading.provider';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const router = createBrowserRouter([
  {
    path: "/",
    element: (<ProtectedRoute> <MainSearch /> </ProtectedRoute>),
  },
  {
    path: "/last",
    element: (<ProtectedRoute> <LastSearches /> </ProtectedRoute>)
  },
  {
    path: "/my-repertoire",
    element: (<ProtectedRoute> <MyRepertoire /> </ProtectedRoute>)
  },
  {
    path: "/collection-list",
    element: (<ProtectedRoute> <CollectionList /> </ProtectedRoute>)
  },
  {
    path: "/be-vip",
    element: <BeMember />
  },
  {
    path: "/contribute",
    element: <Contribute />
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: '*',
    element: <ErrorPage />
  }
]);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <AuthProvider>
        <LoadingProvider>
          <RouterProvider router={router} />
          <Footer />
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="colored"
          />
        </LoadingProvider>
      </AuthProvider>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
