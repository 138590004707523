import React, { createContext, FunctionComponent, ReactNode, useState } from 'react';
import { Processing } from '../components/processing';

interface LoadingProviderProps {
    children: ReactNode
}

interface ProviderValue {
    setIsLoading: (value: boolean) => void,
}

const defaultProviderValue: ProviderValue = {
    setIsLoading: (_: boolean) => { }
}

export const LoadingContext = createContext<ProviderValue>(defaultProviderValue);

export const LoadingProvider: FunctionComponent<LoadingProviderProps> = ({ children }) => {

    const [isLoading, setIsLoading] = useState(false);

    const value: ProviderValue = { setIsLoading }

    return (
        <LoadingContext.Provider value={value}>
            <Processing isLoading={isLoading} />
            {children}
        </LoadingContext.Provider>
    )
}